<template>
  <ListagemChavesApi />
</template>

<script>
import ListagemChavesApi from "@/components/gestao/api/ListagemChavesApi.vue";

export default {
  components: {
    ListagemChavesApi
  }
};
</script>
